"use client";

import { useState } from "react";
// import { useReCaptcha } from "next-recaptcha-v3";
import * as styles from "./styles.css";
import Input from "@/components/input";
import Button from "@/components/button";
import Icon from "@/elements/icons";

interface ContactFormProps {
  formsparkActionURL: string;
  heading: string;
  description: string;
  enquiryTypeDropdown: boolean;
  enquiryTypeDropdownLabel: string;
  enquiryTypeDropdownOptions: any[];
  enquiryTypeDropdownRequired: boolean;
  firstName: boolean;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  firstNameRequired: boolean;
  lastName: boolean;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  lastNameRequired: boolean;
  emailAddress: boolean;
  emailAddressLabel: string;
  emailAddressPlaceholder: string;
  emailAddressRequired: boolean;
  contactNumber: boolean;
  contactNumberLabel: string;
  contactNumberPlaceholder: string;
  contactNumberRequired: boolean;
  message: boolean;
  messageLabel: string;
  messagePlaceholder: string;
  messageRequired: boolean;
  contactPreference: boolean;
  contactPreferenceLabel: string;
  contactPreferenceRequired: boolean;
  submissionHeading: string;
  submissionMessage: string;
}

export default function ContactForm(props: ContactFormProps) {
  const [submitted, setSubmitted] = useState(false);
  // const { executeRecaptcha } = useReCaptcha();
  const [selectedContactPreference, setSelectedContactPreference] =
    useState("Email");
  const handleContactPreferencChange = (e: any) => {
    setSelectedContactPreference(e.target.value);
  };
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const formDataObject = Object.fromEntries(formData.entries());

    // const recaptchaToken = await executeRecaptcha("submit_form");

    // formDataObject["recaptchaToken"] = recaptchaToken;

    try {
      // if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
      await fetch("/api/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataObject),
      });
      // }
      setSubmitted(true);
    } catch (error) {
      console.error("Form submission error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setSubmitted(false);
  };
  return (
    <div className={styles.container}>
      {submitted == false && (
        <>
          <div className={styles.headingContainer}>
            <h2 className={styles.heading}>{props.heading}</h2>
            <p className={styles.description}>{props.description}</p>
          </div>
          <form onSubmit={handleSubmit} className={styles.form}>
            {props.enquiryTypeDropdown && (
              <Input
                type="select"
                label={props.enquiryTypeDropdownLabel}
                required={props.enquiryTypeDropdownRequired}
                className={styles.enquiryTypeDropdown}
              >
                {props.enquiryTypeDropdownOptions.map((option, index) => (
                  <option key={index} value={option.option}>
                    {option.option}
                  </option>
                ))}
              </Input>
            )}
            {props.firstName && (
              <Input
                type="text"
                label={props.firstNameLabel}
                placeholder={props.firstNamePlaceholder}
                required={props.firstNameRequired}
                className={styles.firstName}
              />
            )}
            {props.lastName && (
              <Input
                type="text"
                label={props.lastNameLabel}
                placeholder={props.lastNamePlaceholder}
                required={props.lastNameRequired}
                className={styles.lastName}
              />
            )}
            {props.emailAddress && (
              <Input
                type="email"
                label={props.emailAddressLabel}
                placeholder={props.emailAddressPlaceholder}
                required={props.emailAddressRequired}
                className={styles.emailAddress}
              />
            )}
            {props.contactNumber && (
              <Input
                type="tel"
                label={props.contactNumberLabel}
                placeholder={props.contactNumberPlaceholder}
                required={props.contactNumberRequired}
                className={styles.contactNumber}
              />
            )}
            {props.message && (
              <Input
                type="textarea"
                label={props.messageLabel}
                placeholder={props.messagePlaceholder}
                required={props.messageRequired}
                className={styles.message}
              />
            )}
            {props.contactPreference && (
              <div className={styles.contactPreference}>
                <span className={styles.contactPreferenceLabel}>
                  {props.contactPreferenceLabel}{" "}
                  {props.contactPreferenceRequired && "*"}
                </span>
                <div className={styles.contactPreferenceInputs}>
                  <label htmlFor="email" className={styles.radioButton}>
                    <input
                      type="radio"
                      id="email"
                      name="Contact Preference"
                      value="Email"
                      checked={selectedContactPreference === "Email"}
                      onChange={handleContactPreferencChange}
                    />
                    <span className={styles.checkmark}></span>
                    <span>Email</span>
                  </label>
                  <label htmlFor="phone" className={styles.radioButton}>
                    <input
                      type="radio"
                      id="phone"
                      name="Contact Preference"
                      value="Phone"
                      checked={selectedContactPreference === "Phone"}
                      onChange={handleContactPreferencChange}
                    />
                    <span className={styles.checkmark}></span>
                    <span>Phone</span>
                  </label>
                </div>
              </div>
            )}
            <div className={styles.button}>
              <Button style="primary" label="Submit" type="submit" />
            </div>
          </form>
        </>
      )}
      {submitted == true && (
        <div className={`${styles.headingContainer} ${styles.submitted}`}>
          <div className={styles.submittedIcon}>
            <Icon type="check-circle-filled"></Icon>
          </div>
          <h2 className={styles.heading}>{props.submissionHeading}</h2>
          <p className={styles.description}>{props.submissionMessage}</p>
        </div>
      )}
    </div>
  );
}
