"use client";
import * as styles from "./styles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "@/components/card";
// import { CardLayoutsModule } from "@/types/sanity.types";
import "swiper/css";
import { stegaClean } from "@sanity/client/stega";
import { useMemo } from "react";

export default function CardLayoutsBasicCarousel(props: any) {
  const sliderCount = useMemo(() => {
    return props?.cardLayoutItem?.length ?? props?.sourceData?.length ?? 0
  }, [props]);

  return (
    <PageSection backgroundColor={props.contentColor ?? 'white'}>
      <ContentContainer layout="cardBasicCarousel" className={styles.container}>
        {/* {props.basicCardItem && stegaClean(props.cardContentSelection)?.toLowerCase() === 'manual' && */}
          <Swiper
            spaceBetween={24}
            slidesPerView={'auto'}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            className={`${styles.swiperContainer}${sliderCount < 4 ? ' is--center' : ''}`}
          >
            {props?.cardLayoutItem && stegaClean(props?.cardContentSelection) === 'create cards' && props?.cardLayoutItem.map((item: any) => (
              <SwiperSlide key={item?._key}>
                <div className={styles.cardItem}>
                  {stegaClean(item?._type) === 'manual' &&
                    <Card
                      title={item?.headingText ?? ''}
                      subtitle={item?.subHeadingText ?? ''}
                      button={{ 
                        _type: 'buttonObject',
                        buttonLabel: item?.button?.buttonLabel,
                        buttonVariant: 'tertiary',
                        buttonVisible: item?.button?.buttonVisible ?? (item?.button?.buttonLink ? true : false),
                        buttonLink: item?.button?.buttonLink,
                        linkType: item?.button?.linkType ?? (item?.button?.buttonLink?.includes('http') ? 'external link' : 'internal link'),
                        rollerID: item?.button?.rollerID
                      }}
                      imageSource={props.showCardImage ? (item?.imageSource?.url ?? item?.imagePreview?.url) : ''}
                      imageBlurData={props.showCardImage ? (item?.imageSource?.lqip ?? item?.imagePreview?.lqip) : ''}
                      imageAlt={props.showCardImage  ? (item?.imageSource?.imageAlt ?? item?.imagePreview?.imageAlt) : ''}
                      variant="small"
                      type={stegaClean(props.layoutType) === 'carousel' || stegaClean(props.layoutType) === 'basic' ? 'product' : stegaClean(props.layoutType) === 'articles' ? 'article' : undefined }
                      lineClamp={2}
                    />
                  }
                  {stegaClean(item?._type) === 'source' &&
                    <Card
                      title={item?.cardItemFromSource?.header?.headingCopy ?? item?.cardItemFromSource?.title}
                      // subtitle={item.header.subheadingCopy ?? ''}
                      button={{ 
                        _type: 'buttonObject',
                        buttonLabel: item.cardItemFromSourceButtonLabel, 
                        buttonVariant: 'tertiary',
                        buttonVisible: item?.cardItemFromSource?.buttonLink || item?.cardItemFromSource?.slug ? true : false,
                        buttonLink: item?.cardItemFromSource?.buttonLink ?? item?.cardItemFromSource?.slug,
                        linkType: 'internal link'
                      }}
                      subtitle={item?.cardItemFromSource?.header?.subheadingCopy ?? ''}
                      variant="small"
                      type={stegaClean(props.layoutType) === 'carousel' || stegaClean(props.layoutType) === 'basic' ? 'product' : stegaClean(props.layoutType) === 'articles' ? 'article' : undefined }
                      imageSource={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.url : ''}
                      imageBlurData={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.lqip : ''}
                      imageAlt={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.imageAlt : ''}
                      lineClamp={2}
                    />
                  }
                </div>
              </SwiperSlide>
            ))}

            {props?.sourceList && stegaClean(props?.cardContentSelection) === 'automatic' && props?.sourceData && props?.sourceData.map((item: any, index: number) => (
              <SwiperSlide key={item?._id ?? `sourceData-${index}`}>
                <div className={styles.cardItem}>
                  <Card
                    title={item?.header?.headingCopy ?? item?.title}
                    // subtitle={item.header.subheadingCopy ?? ''}
                    button={{ 
                      _type: 'buttonObject',
                      buttonLabel: props?.buttonLabel, 
                      buttonVariant: 'tertiary',
                      buttonVisible: item?.buttonLink || item?.slug ? true : false,
                      buttonLink: item?.buttonLink ?? item?.slug,
                      linkType: 'internal link'
                    }}
                    subtitle={item?.header?.subheadingCopy ?? ''}
                    variant="small"
                    type={stegaClean(props.layoutType) === 'basic' ? 'product' : stegaClean(props.layoutType) === 'articles' ? 'article' : undefined }
                    imageSource={props.showCardImage ? item?.imagePreview?.url : ''}
                    imageBlurData={props.showCardImage ? item?.imagePreview?.lqip : ''}
                    imageAlt={props.showCardImage ? item?.imagePreview?.imageAlt : ''}
                    lineClamp={2}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
      </ContentContainer>
    </PageSection>
  );
}
