"use client";

import ContentContainer from "@/components/content-container";
import * as styles from "./styles.css";
import { stegaClean } from "@sanity/client/stega";
import PageSection from "@/components/page-section";

interface Props {
  contentColor?: 'white' | 'neutral-lighten-5' | 'background-darken-3'
  dividerColor?: "background-lighten-3" | "background-secondary-lighten-6";
}

export default function ContentDivider(props: Props) {
  return (
    <PageSection backgroundColor={stegaClean(props.contentColor) ?? 'white'}>
      <ContentContainer className="is--module contentContainer" layout="divider">
        <div className={`${styles.container({
          backgroundColor: stegaClean(props?.dividerColor) ?? "background-lighten-3",
        })} is--content-divider`} />
      </ContentContainer>
    </PageSection>
  );
}
