"use client"

import * as styles from "./styles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import HeadingAndText from "../heading-and-text";
import ButtonContainer from "../button-container";
import Button from "../button";
import Image from "next/image";
import { stegaClean } from "@sanity/client/stega";
import { SliderModule } from "@/types/sanity.types";

export default function Slider(props: SliderModule) {
  return (
    <PageSection backgroundColor={stegaClean(props.sliderColor) ?? 'white'}>
      <ContentContainer layout="fullwidth">
        <div className={styles.container}>
          <div>
            {props?.headingText &&
              <div className={styles.headingContainer}>
                <HeadingAndText
                  headingText={props?.headingText}
                  bodyText={props?.bodyText}
                  hasBottomPadding={!props?.button?.buttonVisible}
                  textAlign="center"
                />
              </div>
            }
            {props?.button && props?.button?.buttonVisible && (
              <div className={styles.buttonContainer}>
                <ButtonContainer spacing="default">
                  <Button
                    href={props?.button.buttonLink || ""}
                    label={props?.button?.buttonLabel || ""}
                    style={stegaClean(props?.button.buttonVariant) || "tertiary"}
                    target={props.button.linkType === 'external link' ? '_blank' : ''}
                    rollerProductID={props?.button?.rollerID}
                    linkType={props?.button?.linkType}
                  />
                </ButtonContainer>
              </div>
            )}
          </div>
          <div className={styles.slider}>
            <div className={styles.sliderContent}>
              {props.images &&
                props.images.map((item: any, index: any) => 
                  <div 
                    key={item._key + index}
                    className={`${styles.imageContainer({imageScale: stegaClean(props.imageScale)})} ${styles.slide}`}>
                    <Image
                      src={`${item.url}?h=388&q=80&auto=format`}
                      alt={item.imageAlt ?? ""}
                      fill={true}
                      style={{ objectFit: stegaClean(props.imageScale) }}
                      placeholder="blur"
                      blurDataURL={`${item?.lqip ?? `${item.url}?w=10&auto=format`}`}
                    />
                  </div>
                )
              }
            </div>
            <div className={styles.sliderDuplicateContent}>
              {props.images &&
                props.images.map((item: any, index: any) => 
                  <div 
                    key={`dup-${item._key + index}`} 
                    className={`${styles.imageContainer({imageScale: stegaClean(props.imageScale)})} ${styles.slide}`}>
                    <Image
                      src={`${item.url}?h=388&q=80&auto=format`}
                      alt={item.imageAlt ?? ""}
                      fill={true}
                      style={{ objectFit: stegaClean(props.imageScale) }}
                      placeholder="blur"
                      blurDataURL={`${item?.lqip ?? `${item.url}?w=10&auto=format`}`}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </ContentContainer>
    </PageSection>
  );
}
