"use client";

import * as styles from "./styles.css";
import Image from "next/image";
import { useEffect, useRef } from "react";

interface Props {
  priority?: boolean;
  video: {
    url: string;
  };
  videoMobile?: {
    url: string;
  };
  videoPlaceholder:
    | {
        source: { url: string };
        imageAlt: string;
      }
    | undefined;
}

export default function VideoAutoplay(props: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTimeout(() => {
          videoRef.current?.play();
        }, 2000);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className={styles.videoContainer}>
      {props.videoPlaceholder && !props.video.url && (
        <Image
          src={`${props.videoPlaceholder.source.url}?h=700&q=80&auto=format`}
          alt={props.videoPlaceholder.imageAlt || ""}
          fill={true}
          style={{ objectFit: "cover" }}
          placeholder="blur"
          blurDataURL={props.videoPlaceholder.source.url}
          priority={props.priority}
          loading={props.priority ? "eager" : "lazy"}
        />
      )}
      {props.video.url && (
        <>
          {props.videoPlaceholder && (
            <Image
              src={`${props.videoPlaceholder.source.url}?h=700&q=80&auto=format`}
              alt={props.videoPlaceholder.imageAlt || ""}
              fill={true}
              style={{ objectFit: "cover" }}
              placeholder="blur"
              blurDataURL={props.videoPlaceholder.source.url}
              priority={props.priority}
              loading={props.priority ? "eager" : "lazy"}
            />
          )}
          {(props.video && props?.videoMobile && (
            <picture>
              <source
                srcSet={props.videoMobile.url}
                media="(max-width: 640px)"
                type="video/mp4"
              />
              <source
                srcSet={props.video.url}
                media="(min-width: 641px)"
                type="video/mp4"
              />
              <video
                className={styles.videoItem}
                autoPlay
                loop
                muted
                playsInline
                ref={videoRef}
              >
                <source src={props.video.url} type="video/mp4" />
              </video>
            </picture>
          )) ||
            (props.video && !props?.videoMobile && (
              <video
                className={styles.videoItem}
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={props.video.url} />
              </video>
            ))}
        </>
      )}
    </div>
  );
}
