import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fcard%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA91WbY%2FbNgz%2Bfr%2BCa1EgKSJP9jlO6n4ZVmB%2FY5BtOlHPlgRJ7jkd7r8Pkp34Jc5dr2iBbvmQOCRF8iH5UA7%2BTk4PJmQU%2FrkDyFj%2BcNCyEQXJZSV1Cm%2F%2F8p%2BPTilbYo6skI8pUNVColoIt6oFEqkW9CFjqzDZQBRvIKYboAHdrzffbNlF0AVqolnBG5NCmKjWieUX1GXlwh55UaBwMsWKgotDClHcGRXcqIqdUsgqmT84yRH54WhT4OKImtuPd093QY%2F2%2FgZaq5kwimkUdo5YSIELOdIXE6TjwPF%2Ftcx0qcYDru3%2FFFficdVMH7ggVqoU9s7pYLD3BrNpHNQfvLqUwpKS1bw6pfCF6RUhXsSqDLUlhgnjAXih4V8xhVBj7UQVF0jOkxwG27MYrUVNjGL5AKNPM5PWyrrPFODcifjP7Ta5HyfHRuCugGVXyOfY8tdje%2ByBJJTO8NJgt12GHN3CHHb4LLaWeOKWUtcpNEqhzpnBMfgwDPfRzndeGm65FCmwzMiqsd6uA0g7j7oL7VP8SrgosE0hnAxKWaE3ZRU%2FCMIt1iaFHIVF7cSfG2N5eSK5FBaFHasWOfLpUxRNxjJWrWuI%2F%2B0XXD%2FdVipSYWkvYx7Tib7r%2FpLJ0LnCd%2B6RF%2FY4oD5XPJobp6y0qP2RC543b5Z3bu9zP3XZ%2F312PSz3xcFIYUvfXZp0fh4a7h8rZnFFtvTdBtz3etI7Ek4oG3BDCNOW5xXCMe6RjQcFfuO1ktoyYW%2BfDLxFViFxI%2Fjyopjab%2BCWBtQko2vehuekv2evjPg05dnuFs8IDfoRnHB4Ryc3W%2FQrJnV%2FKfLO53Z2zBorX3VjLLpMMyylxlcxI6Td%2FPZM70YlZ1W%2BWoUUfocPa3jvjdbLQWf3jct4%2FoYxNb1aWVe7ydUUSYb2EbvL0VmSgmvMO0LmsmpqMb3uf2YQRzKlZdHkluRMF6RiJ9lY8ArTZJbbCiccOa%2BRp7s%2Faiw4A5NrRAFMFLCqWUv66u%2BSnWrX%2Fuh0MIbRCJN%2BHz6NjGbEn1yRU9bT%2BdFRP4IjM4Twmh1wFtQPgOs6kC6B9TNuUiHtauRrPXN2ed91HpYrwsVQkf1LFYnihYpcdtAtIt%2Bm8nNkHoeIfn6IVzfH1%2BLHNedfXMZM5X4NAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var buttonContainer = '_6yks1ab';
export var card = _7a468({defaultClassName:'_6yks1a0',variantClassNames:{size:{'default':'_6yks1a1',small:'_6yks1a2'},type:{product:'_6yks1a3',article:'_6yks1a4','article-image':'_6yks1a5'}},defaultVariants:{},compoundVariants:[]});
export var contentContainer = '_6yks1a8';
export var date = '_6yks1a9';
export var dateDivider = '_6yks1aa';
export var image = '_6yks1a7';
export var subtitle = '_6yks1a6';
export var tag = '_6yks1ac';
export var tagIconContainer = '_6yks1ad';