"use client";

import * as styles from "./styles.css";
import Icon from "@/elements/icons";
import Button from "@/components/button";

interface TicketPriceProps {
  index: number;
  passType: string;
  gateCost: string;
  onlineCost: string;
  rollerProductID: string;
}

const handleRollerClick = (rollerProductID: string) => {
  if (typeof window !== "undefined" && window.RollerCheckout) {
    window.RollerCheckout.showProduct({ productId: rollerProductID });
  } else {
    console.error("RollerCheckout is not available.");
  }
};

export default function TicketPrice(props: TicketPriceProps) {
  return (
    <a
      key={props.index}
      className={styles.row}
      href="#"
      onClick={(event) => { event.preventDefault(); handleRollerClick(props.rollerProductID || "")}}
    >
      <div className={styles.rowPassType}>
        <div className={styles.rowPassTypeIcon}>
          <Icon type="ticket-unfilled" />
        </div>
        {props.passType}
      </div>
      <div className={styles.rowGateCost}>{props.gateCost}</div>
      <div className={styles.rowOnlineCost}>{props.onlineCost}</div>
      <div className={styles.rowPurchase}>
        <Button
          label="Purchase"
          style="tertiary"
          noLabelMobile
        />
      </div>
    </a>
  );
}
