import { format } from 'date-fns';
import * as styles from "./styles.css";
import Link from "next/link";
import Image from "next/image";
import Button from "@/components/button";
import { ButtonObject } from "@/types/sanity.types";
import { stegaClean } from "@sanity/client/stega";
import { PortableText } from 'next-sanity';
import { ptComponents } from '@/utils/portableTextComp';
import ButtonContainer from '../button-container';
import Icon from '@/elements/icons';

interface Button extends ButtonObject {
  linkType: string;
  buttonLinkContentType: string;
};

interface CardProps {
  imageSource?: string;
  imageBlurData?: string;
  imageAlt?: string;
  title: string;
  subtitle?: string;
  date?: string;
  button?: ButtonObject;
  type?: "product" | "article" | undefined;
  variant?: "default" | "small";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bodyText?: any[];
  limited?: boolean;
  lineClamp?: 2 | 3 | 4,
}

export default function Card(props: CardProps) {
  return (
    (props?.button?.buttonLink && props?.button?.linkType !== 'roller ID' &&
      <Link 
        href={props?.button?.buttonLink ?? '/'}
        target={props?.button?.buttonLink && props?.button?.buttonLink?.includes('http') ? '_blank' : '_self'}
        className={styles.card({
          size: props.variant ?? 'default',
          type: props.type ?? (props.imageSource ? 'article-image' : 'article')
        })}
        prefetch={false}>
        {props.limited &&
          <div className={styles.tag}>
            <div className={styles.tagIconContainer}>
              <Icon type="exclamation-circle-filled" />
            </div>
            Limited
          </div>
        }
        
        {props.imageSource && (
          <div className={styles.image}>
            <Image
              src={`${props.imageSource}?${props.type !== 'product' ? 'h=480&' : 'h=600&'}q=80&auto=format`}
              alt={props.imageAlt ? props.imageAlt : ""}
              fill={true}
              style={{ objectFit: "cover" }}
              placeholder="blur"
              blurDataURL={`${props?.imageBlurData ?? `${props.imageSource}?w=10&auto=format`}`}
            />
          </div>
        )}
        {props.type !== 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''} is--article`}>
            <div>
              { (props.date) && 
                <div className={styles.date}>
                  {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
                </div>
              }
              <h4>{props.title}</h4>

              {props.subtitle && 
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} is--subtitle portable-text`}>{props.subtitle}</div>
              }

              {props.bodyText && (
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                  <PortableText value={props.bodyText} components={ptComponents} />
                </div>
              )}
            </div>
            {props.button &&
              <div className={styles.buttonContainer}>
                {props.button.buttonVisible && props?.button?.buttonLink &&
                  <ButtonContainer layout="align-left" spacing="mediumMobile">
                    <Button
                      label={props?.button?.buttonLabel ?? ""}
                      style={stegaClean(props?.button.buttonVariant) ?? 'tertiary'}
                      size="small"
                      rollerProductID={props?.button?.rollerID}
                      linkType={props?.button?.linkType}
                    />
                  </ButtonContainer>
                }
              </div>
            }
          </div>
        }
        {props.type === 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''}`}>
            { (props.date) && 
              <div className={styles.date}>
                {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
              </div>
            }
            <h4>{props.title}</h4>

            {props.subtitle && 
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} is--subtitle ${styles.subtitle} portable-text`}>{props.subtitle}</div>
            }

            {props.bodyText && (
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                <PortableText value={props.bodyText} components={ptComponents} />
              </div>
            )}
            {props.button &&
              <div className={styles.buttonContainer}>
                {props.button.buttonVisible && props?.button?.buttonLink &&
                  <ButtonContainer layout="align-left">
                    <Button
                      label={props?.button?.buttonLabel ?? ""}
                      style={stegaClean(props?.button.buttonVariant) ?? 'tertiary'}
                      size="small"
                      rollerProductID={props?.button?.rollerID}
                      linkType={props?.button?.linkType}
                    />
                  </ButtonContainer>
                }
              </div>
            }
          </div>
        }
      </Link>
    ) ||
    (props?.button?.linkType === 'roller ID' &&
      <div 
        className={styles.card({
          size: props.variant ?? 'default',
          type: props.type ?? (props.imageSource ? 'article-image' : 'article')
        })}>
        {props.limited &&
          <div className={styles.tag}>
            <div className={styles.tagIconContainer}>
              <Icon type="exclamation-circle-filled" />
            </div>
            Limited
          </div>
        }
        
        {props.imageSource && (
          <div className={styles.image}>
            <Image
              src={`${props.imageSource}?${props.type !== 'product' ? 'h=480&' : 'h=600&'}q=80&auto=format`}
              alt={props.imageAlt ? props.imageAlt : ""}
              fill={true}
              style={{ objectFit: "cover" }}
              placeholder="blur"
              blurDataURL={`${props?.imageBlurData ?? `${props.imageSource}?w=10&auto=format`}`}
            />
          </div>
        )}
        {props.type !== 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''} is--article`}>
            <div>
              { (props.date) && 
                <div className={styles.date}>
                  {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
                </div>
              }
              <h4>{props.title}</h4>

              {props.subtitle && 
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} is--subtitle portable-text`}>{props.subtitle}</div>
              }

              {props.bodyText && (
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                  <PortableText value={props.bodyText} components={ptComponents} />
                </div>
              )}
            </div>
            {props.button &&
              <div className={styles.buttonContainer}>
                {props.button.buttonVisible && props?.button?.rollerID &&
                  <ButtonContainer layout="align-left" spacing="mediumMobile">
                    <Button
                      label={props?.button?.buttonLabel ?? ""}
                      style={stegaClean(props?.button.buttonVariant) ?? 'tertiary'}
                      size="small"
                      rollerProductID={props?.button?.rollerID}
                      linkType={props?.button?.linkType}
                    />
                  </ButtonContainer>
                }
              </div>
            }
          </div>
        }
        {props.type === 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''}`}>
            { (props.date) && 
              <div className={styles.date}>
                {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
              </div>
            }
            <h4>{props.title}</h4>
            
            {props.subtitle && 
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} ${styles.subtitle} portable-text`}>{props.subtitle}</div>
            }

            {props.bodyText && (
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                <PortableText value={props.bodyText} components={ptComponents} />
              </div>
            )}
            {props.button &&
              <div className={styles.buttonContainer}>
                {props.button.buttonVisible && props?.button?.rollerID &&
                  <ButtonContainer layout="align-left">
                    <Button
                      label={props?.button?.buttonLabel ?? ""}
                      style={stegaClean(props?.button.buttonVariant) ?? 'tertiary'}
                      size="small"
                      rollerProductID={props?.button?.rollerID}
                      linkType={props?.button?.linkType}
                    />
                  </ButtonContainer>
                }
              </div>
            }
          </div>
        }
      </div>
    ) ||
    (!props?.button?.buttonLink &&
      <div 
        className={styles.card({
          size: props.variant ?? 'default',
          type: props.type ?? (props.imageSource ? 'article-image' : 'article')
        })}>
        {props.limited &&
          <div className={styles.tag}>
            <div className={styles.tagIconContainer}>
              <Icon type="exclamation-circle-filled" />
            </div>
            Limited
          </div>
        }
        
        {props.imageSource && (
          <div className={styles.image}>
            <Image
              src={`${props.imageSource}?${props.type !== 'product' ? 'h=480&' : 'h=600&'}q=80&auto=format`}
              alt={props.imageAlt ? props.imageAlt : ""}
              fill={true}
              style={{ objectFit: "cover" }}
              placeholder="blur"
              blurDataURL={`${props?.imageBlurData ?? `${props.imageSource}?w=10&auto=format`}`}
            />
          </div>
        )}
        {props.type !== 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''} is--article`}>
            <div>
              { (props.date) && 
                <div className={styles.date}>
                  {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
                </div>
              }
              <h4>{props.title}</h4>

              {props.subtitle && 
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} is--subtitle portable-text`}>{props.subtitle}</div>
              }

              {props.bodyText && (
                <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                  <PortableText value={props.bodyText} components={ptComponents} />
                </div>
              )}
            </div>
          </div>
        }
        {props.type === 'product' && 
          <div className={`${styles.contentContainer}${props.imageSource ? ' has--image' : ''}`}>
            { (props.date) && 
              <div className={styles.date}>
                {props?.date ? format(new Date(props.date), 'd MMM yyyy') : ''}
              </div>
            }
            <h4>{props.title}</h4>
            
            {props.subtitle && 
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} is--subtitle ${styles.subtitle} portable-text`}>{props.subtitle}</div>
            }

            {props.bodyText && (
              <div className={`${props.lineClamp ? `line-clamp-${props.lineClamp}` : ''} portable-text`}>
                <PortableText value={props.bodyText} components={ptComponents} />
              </div>
            )}
          </div>
        }
      </div>
    )
  );
}
