import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22modules%2Fcontact-form%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81VXW%2BbMBR976%2B4ajUpkeIIKJDUeZm6pX9jMtgQq8ZGttPQTdlvn2xCAvlomfayPBBxfe7h%2BFz73vmPUO1kuCsC%2BHUHsOPUbjCEQfBldQeQkfy11GorKcqVUBrDw4v%2F%2BUWlKdNIE8q3BkOY1k0bbpDZEKp2GIK6gcg9YvdAy7oBXWZkEqYziOIZxMEMgnmQTGcjoWEwXa3u9nfzTnboZVvWWEQELyWGnEnLtFNSEV1yiTJlraowpLET2Mt99LkHlFV1t4ceJPYQyk0tyDuGUnO6urTJhZFlVS2IZc6pbSUNBs1qRuwknkFY6OkR2K6jktQYorg1zS9otWujj9GZjMTL6GVjMDWREA9Q6SjUYhRqOQr1NApFRqGy26heBQrBvGHuH1GuWW65clX3Kd5Kcq2Q%2BbCQHc11u6kHF0paVJCKi3cMb0RPEPIhIjKmLTJEGl9UHzT8J8MQzJeLRLPKhQWXDG0YLzcWQziPjnFmLdPI1CTnssQQHDl2B3CmBB3oYdfF%2BwOPuGWV6R%2F77pvd2aqV4a1Jmgli%2BRvzUUIplyUSrLDYXzoXzbfauGteK97RbY1TywTLLQapJFv9Z95wWW%2Btd%2Bi0U5IZJbbWa1WOzb4f2LqvBr17HAwYiw%2B4fJcIWqmFPaPsDD%2Bwdq9tn8RglOAUwnlSN%2FCwTtaL9fOVNpq4lnK5P5xvWP7KKPyGM6GH%2FK4%2Fr7%2BlL9H34YYwKSzTHp0raZm0GO7vVzd3eTxpbblHqul95UiQCZW%2FnjFczfDGxs6bk7nH167dRu3r8eBEfYOHDvZn13VXylFdaeNRN27aP3cl3h%2B6aTzc3sW84mDeWtUFFwLDQ5w8J2nqMF8rRjkBk2vGJBBJYVKRBh2YF%2BmibqY%2Bcz6c98c%2BcDqu%2Bxt0XJ7olp%2FTdR1l30fFB9THAzOMThNzkL7spw%2BKll5gn%2F4CS25iw%2BgCnH0CvjwFWu3ahd60OSMtP1Ww%2FwOMj8W6qgkAAA%3D%3D%22%7D"
export var button = '_1own1wfg';
export var checkmark = '_1own1wff';
export var contactNumber = '_1own1wf9';
export var contactPreference = '_1own1wfb';
export var contactPreferenceInputs = '_1own1wfc';
export var contactPreferenceLabel = '_1own1wfd';
export var container = '_1own1wf0';
export var description = '_1own1wf3';
export var emailAddress = '_1own1wf8';
export var enquiryTypeDropdown = '_1own1wf5';
export var firstName = '_1own1wf6';
export var form = '_1own1wf4';
export var heading = '_1own1wf2';
export var headingContainer = '_1own1wf1';
export var lastName = '_1own1wf7';
export var message = '_1own1wfa';
export var radioButton = '_1own1wfe';
export var submitted = '_1own1wfh';
export var submittedIcon = '_1own1wfi';