import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22modules%2Fticket-prices%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51U24rbMBB9z1cMlEKWVq7ljS9VXkoX9jeKEk0cgSwJWdtkW%2FbfS%2BTY8TVs9ykwOTozZ84cR79olosqr2P4uwI4SeGPDGgcf96uACwXQuqSQZ7YM8Tb1dsquj34AtHw9c44gY54YxnURkkB1J7h0%2FPz01Pxc%2FCYBnzFXSk12RnvTcXgMbHnASoJqIPRnhx4JdUrg9%2FcrQkJJYGKk9L4o9wTo%2FFh22Jr%2BQcZ0Ch1WF2KSmokR5Tl0TNI2iJ6j47Ulu%2BDQhJHm2LU%2F7E%2FZVCVbEaQTYAIWVvFXxmUTooLuzMnUnLLIFBO9lrxM7mWioaya6Pw4BnwF296RdfM3lR73dP57pdf4rGyinske6NeKl0zcGiR%2BzVNvgI9uLCv5r9mVJo1g3Smt4UlA7jaofOk5roeLz%2BO8tnt04gm6YIDccdxuqJ3RomB3izoDfKayRlQ%2BAa15RrybX8VB4Vhdq5kqYn0WNUM9qg9ugFjPmUsWsbkQ4zFzIzxBygBbgfUo%2Bd3M3HXkmLJk%2FuWvP92exH5r3v%2BUaGQHOq9Q9TAtYB1JXXbJc8Ke34IskdfhlHeO3njyN8EzuY%2Bvd75W79Deu1wP0vFLUrDMLVb6MXp8nnrMvV%2BCxdNXLZxwciBvqyvbxKmzQSfz%2BHTFk8n%2BGIOn7X4xwY%2Fb%2F3twPIsn1j%2F%2FUrcpUgbjVe6fxsYkbnOBgAA%22%7D"
export var container = '_167dm7s0';
export var description = '_167dm7s3';
export var disclaimer = '_167dm7sa';
export var heading = '_167dm7s2';
export var headingContainer = '_167dm7s1';
export var key = '_167dm7s5';
export var keyBadge = '_167dm7s9';
export var keyGateCost = '_167dm7s7';
export var keyOnlineCost = '_167dm7s8';
export var keyPassType = '_167dm7s6';
export var table = '_167dm7s4';