import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22modules%2Fawards%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VWTY%2BbMBC976%2BYqqqUSHFqWAKs99JTf0NvlQMG3AUbGafLttr%2FXtmAgxNYpVI%2FckkwM2%2FezLwZZ%2F81kPJQlAzDzzuAnHdtTV8IlIrnj3dgv5FmTVtTzVAm61MjOgKKtYzqTRDuoOGiof0G7yAo1HbrnAZbVNKWQBi1vXnxA3GRs55A%2BHj3erefYt%2Fb2EeaPZVKnoT1lYrA%2B8%2F2YzyPskddRXP5TAC3PcRtD8Gh7QGFbQ%2BqPNJNEO8gjHYQ4R3gPU63u5sthwgqZwopmvNTRyCIB8ryO1NFbcJWPM%2BZMGctzXMuynNermzHWmZPfm573iGUUZUjKeqXIdO%2FnMws%2FMEGLGpmIiounghgw%2FiZ57oiEKZjBhXjZaXdwQwh%2FhfCoDUvBeKaNR2BjAnN1IxlgPEHj1NiOa04Oaoma3NwqaYZUOonN3ksIs%2FcHharOjOg1sCjD9DKjmsuBQF67GR90syc1qzQY1PceNingtc1AS4qprie94ietPSiRVANI1RIoVFBG16%2FEPhO1QYhe5SzmqJS6opnSApme2FfdPwHIxDsD4o1lgwXDDktTIdMa6ZQ19LMqh7hfZReqMRQ2MHssbWEptIHQZCGCbzjTSuVpkJfOO%2Ft8bFmSLNeW9eGqpILpKWTiSeAKvo%2FGX9qWM4pdJliTAAVOWwa2iPX6vC%2B7beWmiMb2MeZzIQUtvWvK3hczPCiVbzZNBHoWioggI%2Fjj7fgZ3TD5OEKPlyHDx1%2BOASYud2Pbm453psVNQ246%2BdRai2b8%2BR7GJGP4YwN1JXxYTQegAlEZkGa2RjGZ2FNe%2B7xRSwrtEM80VXyeVhRBvbKObm5RgDfTp3mxQvKpNBMaGuXMXRk%2BpkN94kH%2FTBCOwW4Ck4qdUc36CdM8e80GDvy%2BNErLh63zkrTrzbo6na%2BpR6z1i1WP%2FI6j9RQlkVTXyRoWLaLlrFvaeWQLOluvffxVL14JdPhxtBU6cHACmz6p%2FGmClJ8pYIUv62C85gncXIlgnTKwlBIFxjQ0WCawVEQI2Q6lQZAKyq6QqqGDD%2FNH4IvGxSYKm9vk2kSp28TXKzRxNC2yqcX4HPB1vjhMz9PW9Ukau%2B%2BSKYL4%2FLK2IezN9f3xmGJubu%2B%2FniQ118%2Bm4Ef0QsAAA%3D%3D%22%7D"
export var actionContainer = '_1oo5fge6';
export var actionContent = '_1oo5fge8';
export var actionImageContainer = '_1oo5fge9';
export var actionItem = '_1oo5fge7';
export var cardContainer = '_1oo5fge3';
export var container = '_1oo5fge0';
export var contentContainer = '_1oo5fge2';
export var fernContainer = '_1oo5fgea';
export var imageContainer = '_1oo5fge5';
export var offsetGrid = '_1oo5fge1';
export var textContainer = '_1oo5fge4';