"use client";

import * as styles from "./styles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import { stegaClean } from "@sanity/client/stega";
import Image from "next/image";
import Button from "@/components/button";
import { CharacterModule } from "@/types/sanity.types";
import { PortableText } from "next-sanity";
import { ptComponents } from "@/utils/portableTextComp";
import Link from "next/link";

export default function Character(props: CharacterModule) {
  return (
    <PageSection backgroundColor={stegaClean(props?.characterColor) ?? 'background-default'}>
      <ContentContainer layout="character">
        <div className="hidden-anchor" id={stegaClean(props?.headingText)?.toLowerCase().replace(/[^A-Z0-9]/ig, "-")} />
        <div className={styles.container({
          backgroundColor: stegaClean(props?.characterColor) ?? 'background-default'
        })}>
          {props.buttonVisible && 
            <Link 
              href={(props.linkType === 'external link' ? props?.buttonExternalLink : props?.buttonLink) ?? "/"}
              target={`${props.linkType === 'external link' ? '_blank' : '_self'}`}
              className={`${styles.link}${!props?.buttonExternalLink || !props?.buttonInternalLink?.buttonLink ? ' no--button' : ''}`} 
              prefetch={false}
            >
              {props.character && props.character?.imageSource &&
                <div className={styles.imageContainer}>
                  <Image
                    src={`${props.character.imageSource.url}?h=256&q=90&auto=format`}
                    alt={props.character.imageSource.imageAlt ?? ""}
                    fill={true}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    blurDataURL={`${props.character.imageSource?.lqip ?? `${props.character.imageSource.url}?w=10&auto=format`}`}
                  />
                </div>
              }
              <div className={`${styles.textContainer}${props?.buttonExternalLink || props?.buttonInternalLink?.buttonLink ? ' has--button' : ' no--button'}`}>
                {props.headingText &&
                  <h3>{props.headingText}</h3>
                }
                {props.bodyText && (
                  <div className="portable-text">
                    <PortableText value={props.bodyText} components={ptComponents} />
                  </div>
                )}
              </div>
              {(props?.buttonExternalLink || props?.buttonLink) &&
                <div className={styles.buttonContainer}>
                  <Button
                    style="primaryCircular"
                    size="large"
                    circular="right"
                    target={props.buttonExternalLink ? '_blank' : ''}
                  />
                  <Button
                    style="primaryCircular"
                    size="small"
                    circular="right"
                    target={props.buttonExternalLink ? '_blank' : ''}
                  />
                </div>
              }
            </Link>
          }
          {!props.buttonVisible && 
            <div className={styles.link}>
              {props.character && props.character?.imageSource &&
                <div className={`${styles.imageContainer}${props?.buttonExternalLink || props?.buttonInternalLink?.buttonLink ? ' has--button' : ' no--button'}`}>
                  <Image
                    src={`${props.character.imageSource.url}?h=256&q=90&auto=format`}
                    alt={props.character.imageSource.imageAlt ?? ""}
                    fill={true}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    blurDataURL={`${props.character.imageSource?.lqip ?? `${props.character.imageSource.url}?w=10&auto=format`}`}
                  />
                </div>
              }
              <div className={`${styles.textContainer}${props?.buttonExternalLink || props?.buttonInternalLink?.buttonLink ? ' has--button' : ' no--button'}`}>
                {props.headingText &&
                  <h3>{props.headingText}</h3>
                }
                {props.bodyText && (
                  <div className="portable-text">
                    <PortableText value={props.bodyText} components={ptComponents} />
                  </div>
                )}
              </div>
            </div> 
          }
        </div>
      </ContentContainer>
    </PageSection>
  );
}
