"use client";

import * as styles from "./styles.css";
import { stegaClean } from "@sanity/client/stega";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import HeadingAndText from "@/components/heading-and-text";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "@/components/card";
// import { CardLayoutsModule } from "@/types/sanity.types";
import "swiper/css";
import { useState } from "react";
import Button from "@/components/button";

export default function CardLayoutsCarousel(props: any) {
  const [swiper, setSwiper] = useState<any>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [reachEnd, setReachEnd] = useState<boolean>(false);

  return (
    <PageSection backgroundColor={stegaClean(props.contentColor) ?? 'background-lighten-5'}>
      <ContentContainer layout="cardCarousel" className={`${styles.container} is--carousel`}>
        <div className="hidden-anchor" id={stegaClean(props?.headingText)?.toLowerCase().replace(/[^A-Z0-9]/ig, "-")} />

        <div className={`${styles.headingCarouselContainer}${!props.headingText ? ' no--heading' : ''}`}>
          <div className={styles.heading}>
            {props.headingText &&
              <HeadingAndText
                headingText={props.headingText}
                bodyText={props.bodyText}
                textAlign="left"
                isFullWidth
              />
            }
          </div>
          <div className={styles.sliderButtonContainer}>
            <Button onClick={() => swiper.slidePrev()} disabled={activeIndex === 0} style="primaryCircular" circular="left" />
            <Button onClick={() => swiper.slideNext()} disabled={reachEnd} style="primaryCircular" circular="right" />
          </div>
        </div>

        {props.cardLayoutItem  &&
          <Swiper
            spaceBetween={24}
            slidesPerView={'auto'}
            onSlideChange={(swiper) => {setActiveIndex(swiper.activeIndex); if (reachEnd) { setReachEnd(false) } }}
            onReachEnd={() => setReachEnd(true)}
            onSwiper={(swiper) => setSwiper(swiper)}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
            }}
            className={styles.swiperContainer}
          >
            {props?.cardLayoutItem.map((item: any) => (
              <SwiperSlide key={item?._key}>
                <div className={styles.cardItem}>
                  {item?._type === 'manual' &&
                    <Card
                      title={item?.headingText ?? ''}
                      button={{ 
                        _type: 'buttonObject',
                        buttonLabel: item?.button?.buttonLabel,
                        buttonVariant: 'tertiary',
                        buttonVisible: item?.button?.buttonVisible || (item?.button?.buttonLink ? true : false),
                        buttonLink: item?.button?.buttonLink,
                        linkType: item?.button?.linkType ?? (item?.button?.buttonLink?.includes('http') ? 'external link' : 'internal link'),
                        rollerID: item?.button?.rollerID
                      }}
                      subtitle={item?.subHeadingText ?? ''}
                      imageSource={props.showCardImage ? (item?.imageSource?.url ?? item?.imagePreview?.url) : ''}
                      imageBlurData={props.showCardImage ? (item?.imageSource?.lqip ?? item?.imagePreview?.lqip) : ''}
                      imageAlt={props.showCardImage ? (item?.imageSource?.imageAlt ?? item?.imagePreview?.imageAlt) : ''}
                      variant="small"
                      type={stegaClean(props.layoutType) === 'carousel' || stegaClean(props.layoutType) === 'basic' ? 'product' : stegaClean(props.layoutType) === 'articles' ? 'article' : undefined }
                      lineClamp={2}
                    />
                  }
                  {item?._type === 'source' &&
                    <Card
                      title={item.cardItemFromSource.header.headingCopy ?? item.cardItemFromSource.title}
                      // subtitle={item.header.subheadingCopy ?? ''}
                      button={{ 
                        _type: 'buttonObject',
                        buttonLabel: item.cardItemFromSourceButtonLabel, 
                        buttonVariant: 'tertiary',
                        buttonVisible: item.cardItemFromSource.buttonLink ? true : false,
                        buttonLink: item.cardItemFromSource.buttonLink,
                        linkType: item.cardItemFromSource.buttonLink?.includes('http') ? 'external link' : 'internal link',
                      }}
                      subtitle={item?.cardItemFromSource.header?.subheadingCopy ?? ''}
                      variant="small"
                      type={stegaClean(props.layoutType) === 'carousel' || stegaClean(props.layoutType) === 'basic' ? 'product' : stegaClean(props.layoutType) === 'articles' ? 'article' : undefined }
                      imageSource={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.url : ''}
                      imageBlurData={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.lqip : ''}
                      imageAlt={props.showCardImage ? item?.cardItemFromSource?.imagePreview?.imageAlt : ''}
                      lineClamp={2}
                    />
                  }
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        }

        {/* {props.cardItem && stegaClean(props.cardContentSelection)?.toLowerCase() === 'manual' &&
          <Swiper
            spaceBetween={24}
            slidesPerView={'auto'}
            onSlideChange={(swiper) => {setActiveIndex(swiper.activeIndex); if (reachEnd) { setReachEnd(false) } }}
            onReachEnd={() => setReachEnd(true)}
            onSwiper={(swiper) => setSwiper(swiper)}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
            }}
            className={styles.swiperContainer}
          >
            {props.cardItem.map((item: any) => (
              <SwiperSlide key={item._key}>
                <div className={styles.cardItem}>
                  <Card
                    title={item?.headingText ?? ''}
                    button={{ 
                      _type: 'buttonObject',
                      buttonLabel: item.button.buttonLabel, 
                      buttonVariant: item.button.buttonVariant,
                      buttonVisible: item.button.buttonVisible,
                      buttonLink: stegaClean(item.button.linkType) === 'internal link' ? item.button.buttonInternalLink.buttonLink : item.button.buttonLink,
                      linkType: item.button.linkType
                    }}
                    variant="default"
                    type="product"
                    imageSource={item?.imageSource?.url}
                    imageAlt={item?.imageSource?.imageAlt}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        } */}
        {/* {props.cardItemFromSource && stegaClean(props.cardContentSelection)?.toLowerCase() === 'select source' &&
          <Swiper
            spaceBetween={24}
            slidesPerView={'auto'}
            onSlideChange={(swiper) => {setActiveIndex(swiper.activeIndex); if (reachEnd) { setReachEnd(false) } }}
            onReachEnd={() => setReachEnd(true)}
            onSwiper={(swiper) => setSwiper(swiper)}
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
            }}
            className={styles.swiperContainer}
          >
            {props.cardItemFromSource.map((item: any, index) => (
              <SwiperSlide key={`${item._id}${index}`}>
                <div className={styles.cardItem}>
                  <Card
                    title={item.header.headingCopy ?? item.title}
                    // subtitle={item.header.subheadingCopy ?? ''}
                    button={{ 
                      _type: 'buttonObject',
                      buttonLabel: props.cardItemFromSourceButtonLabel, 
                      buttonVariant: 'tertiary',
                      buttonVisible: item.buttonLink ? true : false,
                      buttonLink: item.buttonLink,
                      linkType: stegaClean(item.buttonLink)?.includes('http') ? 'external link' : 'internal link'
                    }}
                    subtitle={item?.header?.subheadingCopy ?? ''}
                    variant="default"
                    type="product"
                    imageSource={item?.imagePreview?.url}
                    imageAlt={item?.imagePreview?.imageAlt}
                    limited={item?.limited}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        } */}
      </ContentContainer>
    </PageSection>
  );
}
