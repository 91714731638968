"use client"
import { stegaClean } from "@sanity/client/stega";
import Image from "next/image";
import * as styles from "./styles.css";

interface PageBreakProps {
  type: "snake" | "pawPrint";
  priority?: boolean;
  backgroundColorBefore?: 
    | "background-darken-3"
    | "background-lighten-5"
    | "status-success-darken-3"
    | "white"
    | "background-default"
    | "neutral-lighten-5"
    | "primary-darken-3"
    | "transparent";
  backgroundColorAfter?: 
    | "background-darken-3"
    | "background-lighten-5"
    | "status-success-darken-3"
    | "white"
    | "background-default"
    | "neutral-lighten-5"
    | "primary-darken-3"
    | "transparent";
}

export default function PageBreak(props: PageBreakProps) {
  return (
    <div className={`${styles.container({ 
      backgroundBefore: stegaClean(props?.backgroundColorBefore),
      backgroundAfter: stegaClean(props?.backgroundColorAfter)
    })} is--${stegaClean(props.type)}`}>
      {stegaClean(props.type) === 'snake' &&
        <Image src="/images/snake.svg" width={4073} height={301} alt="snake" priority={props.priority} />
      }
      {stegaClean(props.type) === 'pawPrint' &&
        <Image src="/images/pawprint.svg" width={2994} height={48} alt="paw print" priority={props.priority} />
      }
    </div>
  );
}
