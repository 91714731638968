import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22modules%2Farticle-faq%2Fsyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VUXY%2BbMBB8z69YqaqUSHEK5ItzXqo%2B9G9UBi%2FEPbAte3MhrfLfK5uEcJc7XcoTDDsz69mFxa%2BUcv%2BkkgT%2BTgBqpyQjbG0jCFlpmkOrPQeHFgVN02wOrdKt6KbJHNLKzWa7K6mvZbWwHLKV7cILqbxtxInHigCIRtWaKcLWc%2FAkHAX098GTqk6sNJpQEwdvRYmsQDoi6t3kPFlcu0xvXfaGsVhDmsG3690rQhYJVkipdM3IWA5531wrXK00KwyRaTksswCPmMuHrN5o9yo38EP11f8eZB0JhSifa2cOOtKM4%2FDlZ7yCaWE65vdCmiOHxHawsR2ka9sBy2wHri7ENN3MIVvNYZXMIVkk%2BWz%2BcGXv4CQ65oRUB88h3fSnNS%2FoqibY7pWUYWRDAu8sQ9GY8nn3ULZHJWnPIU2Sr6ORxaR72VFAeQxocKkajLajhRwVP8XiymhilWhVc%2BLwItyUsQiJpkBHzAvt47Ej6NUf5JAusrXDNqCN0sj2qOo9BXw74EiEjoUljgEkg8TxUpwnyatutrCwxpEoGmSEHcXmrvNd%2FVivN8tQ%2F71FqQT40iFqEFrCtBUdu4S03WxtN4vUkXB4fB3cdWjnDxSVvinmd4qbi%2BJdzm8%2B7hI1oet93mnoM3qAGWp5J5BfBO7%2BGWPGsDjrfm%2BGABqs6LaR58n5H8kY%2Bvv%2FBAAA%22%7D"
export var button = '_1t8s9i08';
export var buttonContainer = '_1t8s9i02';
export var buttonContentContainer = '_1t8s9i07';
export var card = '_1t8s9i05';
export var columnBreak = '_1t8s9i04';
export var container = '_1t8s9i00';
export var contentContainer = '_1t8s9i03';
export var heading = '_1t8s9i09';
export var textContainer = '_1t8s9i01';
export var textContentContainer = '_1t8s9i06';