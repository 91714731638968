"use client"

import Icon from "@/elements/icons";
import * as styles from "./styles.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface AccordionItemProps {
  children?: React.ReactNode;
  title: string;
  index: number;
  resize: any;
  clickEmitHandler: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
  mode: 'multi' | 'single';
  variant?: 'default' | 'small';

}
type Accordion = 'closed' | 'opened' | 'closing'

export default function AccordionItem(props: AccordionItemProps) {
  const accordionRef = useRef<HTMLDivElement>(null);
  const accordionContentRef = useRef<HTMLDivElement>(null);
  const accordionTrigger = useRef<HTMLButtonElement>(null);
  const [closedHeight, setClosedHeight] = useState(0);
  const [openedHeight, setOpenedHeight] = useState(0);
  const [accordionState, setAccordionState] = useState<Accordion>('closed');

  const accordionStyles: React.CSSProperties = useMemo(() => {
    if (accordionState === 'opened') {
      return {
        height: `${openedHeight}px`
      }
    } else {
      return {
        height: closedHeight === 0 ? "auto" : `${closedHeight}px`
      }
    }
  }, [accordionState, closedHeight, openedHeight]);

  const clickHandler = useCallback(() => {
    if (accordionState === 'closed') {
      setAccordionState('opened');
      props.clickEmitHandler(props.index);
    } else if (accordionState === 'opened') {
      setAccordionState('closing');
      props.clickEmitHandler(0);

      setTimeout(() => {
        setAccordionState('closed');
      }, 300);
    }
  }, [accordionState, props]);

  const setValues = () => {
    if (accordionTrigger.current) {
      const accordionDefaultH = accordionTrigger.current.offsetHeight;
      setClosedHeight(accordionDefaultH);

      if (accordionContentRef.current) {
        setOpenedHeight(accordionContentRef.current.offsetHeight + accordionDefaultH);
      }
    }
  }

  useEffect(() => {
    setValues();
  }, [props.resize]);

  useEffect(() => {
    if (props.mode === 'single' && props.activeIndex !== 0 && props.activeIndex !== props.index && accordionState === 'opened') {
      clickHandler();
    }
  }, [props.activeIndex, accordionState, clickHandler, props.index, props.mode]);

  return (
    <div ref={accordionRef} style={accordionStyles} className={`${styles.container}${` is--${accordionState} variant--${props.variant}`} content--narrow`}>
      <button ref={accordionTrigger} className={styles.button} onClick={clickHandler} disabled={accordionState === 'closing'}>
        {props.title && 
          <h4 className={styles.heading}>{props.title}</h4>
        }
        <div className={styles.iconContainer}>
          <Icon type="chevron-down-unfilled" />
          <Icon type="chevron-up-unfilled" />
        </div>
      </button>
      <div ref={accordionContentRef} className={styles.contentContainer}>
        {props?.children}
      </div>
    </div>
  );
}
