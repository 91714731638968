"use client"

import { ptComponents } from "@/utils/portableTextComp";
import { PortableText } from "@portabletext/react";
import AccordionItem from "../accordion-item";
import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

interface AccordionProps {
  listData: any;
  mode: 'multi' | 'single';
  variant?: 'default' | 'small'
}
export default function Accordion(props: AccordionProps) {
  const [activeItem, setActiveItem] = useState<number>(0);
  const [resize, setResize] = useState<boolean>(true);
  let resizeTimeout: any;

  const resizeHandler = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    setResize(true);

    resizeTimeout = setTimeout(() => {
      setResize(false);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('resize', debounce(resizeHandler, 300));
  }, []);

  return (
    <div>
      {props.listData.map((item: any, index: number) => (
        <AccordionItem 
          key={`${item._key}-${index}`} 
          title={item.title} index={index + 1} 
          clickEmitHandler={setActiveItem} 
          resize={resize} 
          activeIndex={activeItem} 
          mode={props.mode}
          variant={props.variant ?? 'default'}>
          {item.bodyText && (
            <div className="portable-text">
              <PortableText value={item.bodyText} components={ptComponents} />
            </div>
          )}
        </AccordionItem>
      ))}
    </div>
  );
}
