"use client";

import * as styles from "./styles.css";
import { useState } from "react";
import { BackgroundGraphicTypes } from "@/components/page-section";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Image from "next/image";
import Button from "@/components/button";

interface Content {
  imgSrc: string;
  imgAlt?: string;
  title?: string;
  headingText?: string;
  _type?: string;
  _key?: string;
  imageSource?: any;
}

interface FullwidthCarouselProps {
  backgroundGraphic?: BackgroundGraphicTypes;
  layout?: 'listing' | undefined
  content: Content[];
}

export default function FullwidthCarousel(props: FullwidthCarouselProps) {
  const [swiper, setSwiper] = useState<any>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [reachEnd, setReachEnd] = useState<boolean>(false);
  return (
    <PageSection backgroundColor="supporting-lighten-7" backgroundGraphic={props.backgroundGraphic && props.backgroundGraphic}>
      <ContentContainer>
        <div className={`${styles.container} ${props.layout === 'listing' ? styles.listing : ''}`}>
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            className={styles.swiper}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
              if (reachEnd) {
                setReachEnd(false);
              }
            }}
            onReachEnd={() => setReachEnd(true)}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {props.content.map((item: any, index) => (
              <SwiperSlide key={`${item._key + index}`}>
                <div className={styles.image}>
                  <Image
                    src={`${item?.imageSource?.url}?q=80&h=800&auto=format`}
                    fill={true}
                    alt={item?.imageSource?.imageAlt || ""}
                    style={{ objectFit: "cover", objectPosition: "center" }}
                  ></Image>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={`${styles.nav}${props.content.length === 1 ? ' hide' : ''}`}>
            <Button
              onClick={() => swiper.slidePrev()}
              disabled={activeIndex === 0}
              style="secondaryCircular"
              size="small"
              circular="left"
            />
            <div className={styles.legend}>
              <span className={styles.title}>
                {props.content[activeIndex]?.headingText}
              </span>
              Showing {activeIndex + 1} of {props.content.length} images
            </div>
            <Button
              onClick={() => swiper.slideNext()}
              disabled={reachEnd}
              style="secondaryCircular"
              size="small"
              circular="right"
            />
          </div>
        </div>
      </ContentContainer>
    </PageSection>
  );
}
