"use client"

import * as styles from "./styles.css";
import { stegaClean } from "@sanity/client/stega";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import Icon from "@/elements/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import Card from "@/components/card";
import { useSearchParams } from "next/navigation";

interface Props {
  builderData: any
}

export default function SourceTabs(props: Props) {
  const [activeId, setActiveId] = useState(0);
  const [isOverflow, setIsOverflow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonItemContainerRef = useRef<HTMLDivElement>(null);
  const searchParams = useSearchParams()
  const category = searchParams.get('category');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const queryData = async (sourceList: string, category: string) => {
    try {
      const response = await fetch("/api/listing", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sourceList,
          currentCategory: category
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const {data} = await response.json();

      if (data) {
        setData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  }

  const scrollToRow = (selector: string) => {
    const el = document.querySelector<HTMLElement>(selector);
    buttonItemContainerRef.current?.scrollTo({
      left: el?.offsetLeft,
      behavior: 'smooth',
    });
  };

  const checkIfOverflow = () => {
    if (containerRef.current && buttonItemContainerRef.current) {
      const mainWidth = containerRef.current.offsetWidth;
      const buttonContainerChild = buttonItemContainerRef.current.children;
      let buttonContainerWidth = 0;

      for (let i = 0; i < buttonContainerChild.length; i += 1) {
        const child = buttonContainerChild[i] as HTMLElement;
        buttonContainerWidth += child.offsetWidth;
      }

      if (mainWidth >= buttonContainerWidth) {
        setIsOverflow(false);
      } else {
        setIsOverflow(true);
      }
    }
  }
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set(name, value)
 
      return params.toString()
    },
    [searchParams]
  );

  const buttonNavHandler = (type: any) => {
    if (type === 'next' && activeId + 1 <= props.builderData.categories.length - 1) {
      setActiveId(activeId + 1)
      changeTab(activeId + 1, stegaClean(props.builderData?.categories[activeId + 1]?.slug))
      scrollToRow(`#${stegaClean(props.builderData?.categories[activeId + 1]?.slug)}`)
    } else if (type === 'prev' && activeId - 1 >= 0) {
      setActiveId(activeId - 1)
      changeTab(activeId - 1, stegaClean(props.builderData?.categories[activeId - 1]?.slug))
      scrollToRow(`#${stegaClean(props.builderData?.categories[activeId - 1]?.slug)}`)
    }
  }

  const changeTab = (index: number, id: string) => {
    setActiveId(index);
    setData([]);
    setLoading(true);

    window.history.pushState(null, '', `?${createQueryString('category', id)}`)
  }
  
  useEffect(() => {
    if (loading && category) {
      queryData(props.builderData.sourceList, category);
    }
  }, [category])

  useEffect(() => {
    checkIfOverflow();

    if (!category) {
      setLoading(true);
      setActiveId(0);
      queryData(props.builderData.sourceList, props.builderData?.categories[0]?.slug);
    } else {
      const id = props.builderData?.categories.findIndex((d: any) => d.slug === category);
      setLoading(true);
      setActiveId(id);
      scrollToRow(`#${stegaClean(props.builderData?.categories[id]?.slug)}`);
      queryData(props.builderData.sourceList, category);
    }
 
    window.addEventListener('resize', debounce(checkIfOverflow, 300));
  }, []);

  return (
    <PageSection>
      <ContentContainer layout="source">
        <div ref={containerRef} className={`${styles.container} is--module is--articlemodule--article-tabs `}>
          <div className={styles.buttonContainer}>
            <div className={`${styles.buttonNavContainer}${isOverflow ? ' show' : ''} is--prev`}>
              <div className={`${styles.buttonIndicator} is--prev`}>
                <button className={styles.iconContainer} onClick={() => buttonNavHandler('prev') } disabled={activeId - 1 < 0}>
                  <Icon type="chevron-left-unfilled" />
                </button>
              </div>
            </div>
            <div className={`${styles.buttonNavContainer}${isOverflow ? ' show' : ''} is--next`}>
              <div className={`${styles.buttonIndicator} is--next`}>
                <button className={styles.iconContainer} onClick={() => buttonNavHandler('next') } disabled={activeId + 1 > props.builderData.categories.length - 1}>
                  <Icon type="chevron-right-unfilled" />
                </button>
              </div>
            </div>
            <div ref={buttonItemContainerRef} className={styles.buttonItemContainer}>
              { props.builderData.categories?.map((item: any, index: number) => (
                <button key={item._id} className={`${stegaClean(props.builderData?.categories[activeId]?.slug) === stegaClean(item.slug) ? 'is--active' : 'is--normal'}`} onClick={() => changeTab(index, stegaClean(item.slug))} id={stegaClean(item.slug)}>{item.title}</button>
              ))}
            </div>
          </div>
          <div className={styles.listContainer}>
            {data?.map((item: any) => (
              <div key={`item-${item._id}`} className={styles.contentContainer}>
                <Card
                  title={item?.header?.headingText ?? item.title}
                  button={{ 
                    _type: 'buttonObject',
                    buttonLabel: props.builderData.buttonLabel, 
                    buttonVariant: 'tertiary',
                    buttonVisible: true,
                    buttonLink: item.buttonLink,
                    linkType: 'internal',
                  }}
                  bodyText={item.summary}
                  imageSource={item?.imagePreview?.url}
                  imageBlurData={item?.imagePreview?.lqip}
                  imageAlt={item?.imagePreview?.imageAlt}
                  variant="small"
                  type={'article'}
                  lineClamp={2}
                />
              </div>
            ))}
            {loading && 'Loading...'}
          </div>
        </div>
      </ContentContainer>
    </PageSection>
  );
}
