"use client";

import * as styles from "./styles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import { stegaClean } from "@sanity/client/stega";
import Image from "next/image";
import ButtonContainer from "@/components/button-container";
import Button from "@/components/button";
import { AwardCard, ButtonObject, HomeAwardModule } from "@/types/sanity.types";
import { PortableText } from "next-sanity";
import { ptComponents } from "@/utils/portableTextComp";
import Link from "next/link";
import FernL from "@/elements/graphic/svg/fernL";

interface Props extends HomeAwardModule {
  awardCard: AwardCard,
  isHome: boolean,
  overrideButton?: boolean
  buttonPrimary?: ButtonObject
  buttonSecondary?: ButtonObject
}

export default function Awards(props: Props) {
  return (
    <PageSection backgroundColor="background-default">
      <ContentContainer layout={props.isHome ? 'awards' : 'awardsCard'} className="contentContainer">
        <div className={styles.container}>
          <div className={styles.offsetGrid} />
          <div className={styles.contentContainer}>
            <div className={`${styles.cardContainer}${!props.isHome ? " is--card-only" : ""}`}>
              <div className={styles.textContainer}>
                {props.awardCard?.cardHeadingText &&
                  <h3>{props.awardCard?.cardHeadingText}</h3>
                }
                {props.awardCard?.cardBodyText && (
                  <div className="portable-text">
                    <PortableText value={props.awardCard.cardBodyText} components={ptComponents} />
                  </div>
                )}
                {!props?.overrideButton &&
                  <ButtonContainer layout="align-left" spacing="large">
                    {props?.awardCard?.buttonPrimary &&
                      <Button
                        size="large"
                        href={props.awardCard.buttonPrimary.buttonLink || ""}
                        label={props.awardCard.buttonPrimary.buttonLabel || ""}
                        style={stegaClean(props.awardCard.buttonPrimary.buttonVariant) ?? 'primary'}
                        target={stegaClean(props.awardCard.buttonPrimary.linkType) === 'external link' ? '_blank' : ''}
                        rollerProductID={props.awardCard?.buttonPrimary?.rollerID}
                        linkType={props.awardCard?.buttonPrimary?.linkType}
                      />
                    }
                    {props.awardCard.buttonSecondary &&
                      <Button
                        size="large"
                        href={props.awardCard.buttonSecondary?.buttonLink || ""}
                        label={props.awardCard.buttonSecondary.buttonLabel || ""}
                        style={stegaClean(props.awardCard.buttonSecondary.buttonVariant) ?? 'tertiary'}
                        target={stegaClean(props.awardCard.buttonSecondary.linkType) === 'external link' ? '_blank' : ''}
                        rollerProductID={props.awardCard?.buttonSecondary?.rollerID}
                        linkType={props.awardCard?.buttonSecondary?.linkType}
                      />
                    }
                  </ButtonContainer>
                }
                {props?.overrideButton &&
                  <ButtonContainer layout="align-left" spacing="large">
                    {props?.buttonPrimary &&
                      <Button
                        size="large"
                        href={props.buttonPrimary?.buttonLink || ""}
                        label={props.buttonPrimary.buttonLabel || ""}
                        style={stegaClean(props.buttonPrimary.buttonVariant) ?? 'primary'}
                        target={stegaClean(props.buttonPrimary.linkType) === 'external link' ? '_blank' : ''}
                        rollerProductID={props?.buttonPrimary?.rollerID}
                        linkType={props?.buttonPrimary?.linkType}
                      />
                    }
                    {props.buttonSecondary &&
                      <Button
                        size="large"
                        href={props.buttonSecondary?.buttonLink || ""}
                        label={props.buttonSecondary.buttonLabel || ""}
                        style={stegaClean(props.buttonSecondary.buttonVariant) ?? 'tertiary'}
                        target={stegaClean(props.buttonSecondary.linkType) === 'external link' ? '_blank' : ''}
                        rollerProductID={props?.buttonSecondary?.rollerID}
                        linkType={props?.buttonSecondary?.linkType}
                      />
                    }
                  </ButtonContainer>
                }
              </div>
              {props.awardCard?.cardImageSource &&
                <div className={styles.imageContainer}>
                  <Image
                    src={`${props?.awardCard.cardImageSource.url}?h=568&q=90&auto=format`}
                    alt={props?.awardCard.cardImageSource.imageAlt ?? ""}
                    fill={true}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    blurDataURL={`${props.awardCard.cardImageSource?.lqip ?? `${props.awardCard.cardImageSource.url}?w=10&auto=format`}`}
                  />
                </div>
              }
            </div>

            {props.isHome &&
              <div className={styles.actionContainer}>
                <Link 
                  href={(stegaClean(props.visitLinkType) === 'external link' ? props.visitButtonLink : props?.visitButtonInternalLink?.buttonLink) ?? '/'}
                  target={stegaClean(props.visitLinkType) === 'external link' ? '_blank' : '_self'}
                  className={styles.actionItem}
                  prefetch={false}>
                  <div className={styles.actionContent}>
                    {props.visitImageSource &&
                      <div className={styles.actionImageContainer}>
                        <Image
                          src={`${props?.visitImageSource.url}?h=360&q=80&auto=format`}
                          alt={props?.visitImageSource.imageAlt ?? ""}
                          fill={true}
                          style={{ objectFit: "cover" }}
                          placeholder="blur"
                          blurDataURL={`${props.visitImageSource?.lqip ?? `${props.visitImageSource.url}?w=10&auto=format`}`}
                        />
                      </div>
                    }
                    {props.visitButtonLabel &&
                      <h4>{props.visitButtonLabel}</h4>
                    }
                  </div>
                  <Button
                    size="large"
                    style="primaryCircular"
                    circular="right"
                  />
                </Link>
                <Link 
                  href={(stegaClean(props.showtimeLinkType) === 'external link' ? props.showtimeButtonLink : props?.showtimeButtonInternalLink?.buttonLink) ?? '/'}
                  target={stegaClean(props.showtimeLinkType) === 'external link' ? '_blank' : '_self'}
                  className={styles.actionItem}
                  prefetch={false}>
                  <div className={styles.actionContent}>
                    {props.showtimeImageSource &&
                      <div className={styles.actionImageContainer}>
                        <Image
                          src={`${props?.showtimeImageSource.url}?h=360&q=80&auto=format`}
                          alt={props?.showtimeImageSource.imageAlt ?? ""}
                          fill={true}
                          style={{ objectFit: "cover" }}
                          placeholder="blur"
                          blurDataURL={`${props.showtimeImageSource?.lqip ?? `${props.showtimeImageSource.url}?w=10&auto=format`}`}
                        />
                      </div>
                    }
                    {props.showtimeButtonLabel &&
                      <h4>{props.showtimeButtonLabel}</h4>
                    }
                  </div>
                  <Button
                    size="large"
                    style="primaryCircular"
                    circular="right"
                  />
                </Link>
              </div>
            }
          </div>
        </div>
      </ContentContainer>

      {props.isHome && 
        <div className={styles.fernContainer}>
          <div className="graphic-content__container">
          <FernL />
          </div>
        </div>
      }
    </PageSection>
  );
}
