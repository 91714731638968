import * as styles from "./styles.css";

interface InputProps {
  type: "text" | "email" | "tel" | "select" | "textarea";
  id?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  children?: React.ReactNode;
  className?: string;
  autofocus?: boolean;
}

export default function Input(props: InputProps) {
  return (
    <div className={`${props.className} ${styles.container}`}>
      <label htmlFor={props.label}>
        {props.label} {props.required && "*"}
      </label>
      {props.type === "select" && (
        <div className={styles.selectContainer}>
          <select id={props.id} name={props.label} required={props.required}>
            {props.children}
          </select>
        </div>
      )}
      {props.type === "textarea" && (
        <textarea
          id={props.id}
          name={props.label}
          placeholder={props.placeholder}
          required={props.required}
        />
      )}
      {(props.type === "text" ||
        props.type === "email" ||
        props.type === "tel") && (
        <input
          type={props.type}
          id={props.id}
          name={props.label}
          placeholder={props.placeholder}
          required={props.required}
          aria-label={props.label}
          autoFocus={props.autofocus}
        />
      )}
    </div>
  );
}
